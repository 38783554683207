import { GroupMembership } from '~/types/groups.model';
import { User } from '~/types/user.model';
import { useKeycloakHelper } from './useKeycloakHelper';
const useUserInfoState = () => useState<User>('userInfo', undefined);

const useUserGroupState = () =>
  useState<GroupMembership[] | undefined>('userGroups', undefined);

const useAdminGroupState = () => useState<string[]>('adminGroups', undefined);
const {
  hasAccountRole,
  hasRealmRole,
  hasViewGroupsRole,
  hasViewUsersRole,
  hasManageUserRole,
  hasManageGroupsRole,
} = useKeycloakHelper();

export const useKeycloakState = () => {
  const { fetchUserInfo, fetchUserGroups, fetchManagableGroups } =
    useKeycloakApi();

  const getUserInfo = async () => {
    const state = useUserInfoState();

    if (!state.value) {
      const { data } = await fetchUserInfo();

      const value = data.value;
      state.value = {
        id: value?.sub ?? '',
        firstName: value?.given_name ?? '',
        lastName: value?.family_name ?? '',
        username: value?.preferred_username ?? '',
        email: value?.email ?? '',
        emailVerified: value?.email_verified ?? false,
        group_membership: value?.group_membership,
        realm_access: value?.realm_access,
        resource_access: value?.resource_access,
        enabled: true,
      };
    }

    return state;
  };

  const getUserGroup = async () => {
    const state = useUserGroupState();

    if (!state.value) {
      const user = await getUserInfo();
      const { data } = await fetchUserGroups(user.value?.id ?? '');
      state.value = data.value!;
    }

    return state;
  };

  const getAdminGroups = async () => {
    const state = useAdminGroupState();
    if (state.value === undefined) {
      const { data } = await fetchManagableGroups();
      state.value = data.value!;
    }

    return state;
  };

  const canViewUsersGlobaly = computed(() =>
    hasViewUsersRole(useUserInfoState().value),
  );

  const canViewGroupsGlobaly = computed(() =>
    hasViewGroupsRole(useUserInfoState().value),
  );

  const canManageUsersGlobaly = computed(() =>
    hasManageUserRole(useUserInfoState().value),
  );

  const canManageGroupsGlobaly = computed(() =>
    hasManageUserRole(useUserInfoState().value),
  );

  return {
    getUserInfo,
    getUserGroup,
    canViewUsersGlobaly,
    canViewGroupsGlobaly,
    canManageUsersGlobaly,
    canManageGroupsGlobaly,
    getAdminGroups,
  };
};
