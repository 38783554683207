import { defineNuxtPlugin } from '#app';
import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import Checkbox from 'primevue/checkbox';
import Menu from 'primevue/menu';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Listbox from 'primevue/listbox';
import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Skeleton from 'primevue/skeleton';
import Dropdown from 'primevue/dropdown';
import Toolbar from 'primevue/toolbar';
import Panel from 'primevue/panel';
import Card from 'primevue/card';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import Tree from 'primevue/tree';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Divider from 'primevue/divider';
import Calendar from 'primevue/calendar';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import ProgressSpinner from 'primevue/progressspinner';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(PrimeVue, { ripple: true });
  nuxtApp.vueApp.component('PrimeButton', Button);
  nuxtApp.vueApp.component('InputText', InputText);
  nuxtApp.vueApp.component('InputNumber', InputNumber);
  nuxtApp.vueApp.component('InputSwitch', InputSwitch);
  nuxtApp.vueApp.component('Checkbox', Checkbox);
  nuxtApp.vueApp.component('Menu', Menu);
  nuxtApp.vueApp.component('TabView', TabView);
  nuxtApp.vueApp.component('TabPanel', TabPanel);
  nuxtApp.vueApp.component('Listbox', Listbox);
  nuxtApp.vueApp.component('Dialog', Dialog);
  nuxtApp.vueApp.component('DataTable', DataTable);
  nuxtApp.vueApp.component('Column', Column);
  nuxtApp.vueApp.component('Skeleton', Skeleton);
  nuxtApp.vueApp.component('Dropdown', Dropdown);
  nuxtApp.vueApp.component('Toolbar', Toolbar);
  nuxtApp.vueApp.component('Panel', Panel);
  nuxtApp.vueApp.component('Card', Card);
  nuxtApp.vueApp.component('Toast', Toast);
  nuxtApp.vueApp.component('ConfirmDialog', ConfirmDialog);
  nuxtApp.vueApp.component('Tree', Tree);
  nuxtApp.vueApp.component('Accordion', Accordion);
  nuxtApp.vueApp.component('AccordionTab', AccordionTab);
  nuxtApp.vueApp.component('Divider', Divider);
  nuxtApp.vueApp.component('Calendar', Calendar);
  nuxtApp.vueApp.component('Tag', Tag);
  nuxtApp.vueApp.component('ProgressSpinner', ProgressSpinner);
  nuxtApp.vueApp.use(ToastService);
  nuxtApp.vueApp.use(ConfirmationService);
});
