import { Space } from '~/server/utils/matrixbot';
import { GroupMember } from '~/types/group-member.model';
import { GroupMembership, GroupInfo } from '~/types/groups.model';
import { KCGroup } from '~/types/groups.model';
import { ClaimInfo, InviteLink } from '~/types/invitelink.model';
import { User } from '~/types/user.model';
import { UserInfo } from '~/types/userInfo.model';

export const useKeycloakApi = () => {
  const config = useRuntimeConfig();
  const fetchKeycloak = <T>(url: string) => {
    return useFetch<T>(url, {
      // server: false,
      cache: 'no-cache',
      headers: {
        Authorization: 'bearer ' + getAccessToken(),
      },
    });
  };

  const fetchApi = <T>(url: string, allowUnauthentited: boolean = false) => {
    const accessToken = getAccessToken();
    if (!accessToken && !allowUnauthentited) {
      throw new Error('Got no access token');
    }
    let authHeaderPart = {};
    if (accessToken) {
      authHeaderPart = { Authorization: accessToken };
    }
    return useFetch<T>(url, {
      cache: 'no-cache',
      headers: {
        ...authHeaderPart,
      },
    });
  };

  const makeRequestToApi = <T>(
    url: string,
    method: 'GET' | 'POST' | 'DELETE' | 'PUT' = 'GET',
    body?: Record<string, any>,
  ) => {
    const accessToken = getAccessToken() ?? '';
    return $fetch<T>(url, {
      method,
      // server: false,
      cache: 'no-cache',
      headers: {
        Authorization: accessToken,
        ...(body ? { 'Content-Type': 'application/json' } : {}), // Set content type only, when there is content
      },
      body,
    });
  };

  const postApi = <T>(url: string, body?: Record<string, any>) => {
    return makeRequestToApi<T>(url, 'POST', body);
  };

  const putApi = <T>(url: string, body?: Record<string, any>) => {
    return makeRequestToApi(url, 'PUT', body);
  };

  const deleteApi = <T>(url: string, body?: Record<string, any>) => {
    return makeRequestToApi(url, 'DELETE', body);
  };

  const getAccessToken = () => {
    try {
      const { data } = useAuth();
      return (data.value?.user as any)?.access_token as string | undefined;
    } catch (error) {
      console.error('getAccessToken error', error);
    }

    return undefined;
  };

  const fetchUserInfo = () =>
    fetchKeycloak<UserInfo>(
      `${config.public.authIssuer}/protocol/openid-connect/userinfo`,
    );

  // const updateProfile = (user: User) =>
  //   postKeycloak(`${config.public.authIssuer}/account`, {
  //     id: user.id,
  //     username: user.username,
  //     firstName: user.firstName,
  //   });

  const fetchMyGroups = () =>
    fetchApi<GroupMembership[]>('/api/users/me/groups');

  const fetchUser = (userId: string) => {
    const url = `${config.public.keycloakAdminApi}/users/${userId}`;
    return fetchKeycloak<User>(url);
  };

  const fetchUserGroups = (userId: string) =>
    fetchKeycloak<GroupMembership[]>(
      `${config.public.keycloakAdminApi}/users/${userId}/groups`,
    );

  const fetchManagableGroups = () =>
    fetchApi<string[]>(`/api/groups/managable-groups`);

  const fetchGroupMembers = (groupId: string) =>
    fetchApi<GroupMember[]>(`/api/groups/${groupId}/members`);

  const postGroupMembers = (
    groupId: string,
    addMembers?: string[],
    removeMembers?: string[],
  ) =>
    postApi(`/api/groups/${groupId}/members`, {
      addMembers: addMembers,
      removeMembers: removeMembers,
    });

  const fetchUserCount = (search?: string | undefined) => {
    const searchParam = search ? `?search=${search}` : '';
    return fetchApi<number>(`/api/users/count${searchParam}`);
  };

  const fetchUsers = (
    offset: number,
    max: number,
    search?: string | undefined,
  ) => {
    const searchParam = search ? `&search=${search}` : '';
    return fetchApi<User[]>(
      `/api/users/?first=${offset}&max=${max}${searchParam}`,
    );
  };

  const fetchManagableSpaces = () => {
    return fetchApi<Space[]>(`/api/users/me/managable-spaces`);
  };
  const fetchCurrentSpacesOfGroup = (groupId: string) => {
    return fetchApi<Space[]>(
      `/api/groups/${encodeURIComponent(groupId)}/spaces/`,
    );
  };
  const addSpace = (groupId: string, spaceId: string) => {
    return postApi<void>(`/api/groups/${encodeURIComponent(groupId)}/spaces`, {
      spacesToAdd: [spaceId],
    });
  };
  const removeSpace = (groupId: string, spaceId: string) => {
    return postApi<void>(`/api/groups/${encodeURIComponent(groupId)}/spaces`, {
      spacesToRemove: [spaceId],
    });
  };

  const getAdmins = (groupId: string) => {
    return fetchApi<string[]>(
      `/api/groups/${encodeURIComponent(groupId)}/admins`,
    );
  };

  const getSpaceMemberOverview = (groupId: string) => {
    return fetchApi<GroupMemberOverview>(
      `/api/groups/${encodeURIComponent(groupId)}/spaceMemberOverview`,
    );
  };

  const inviteUserToSpace = (
    groupId: string,
    userId: string,
    spaceId: string,
  ) => {
    return postApi<string>(
      `/api/groups/${encodeURIComponent(
        groupId,
      )}/spaceInvite?space=${encodeURIComponent(
        spaceId,
      )}&user=${encodeURIComponent(userId)}`,
    );
  };

  const createNewSpace = (groupId: string, spaceName: string) => {
    return postApi<string>(
      `/api/groups/${encodeURIComponent(
        groupId,
      )}/spaceCreate?spaceName=${encodeURIComponent(spaceName)}`,
    );
  };

  const getUser = (kcId: string) => {
    return fetchApi<User>(`/api/users/${encodeURIComponent(kcId)}`);
  };

  const changeAdmins = (
    groupId: string,
    newAdmins: string[],
    removeAdmins: string[],
  ) => {
    return postApi<void>(`/api/groups/${encodeURIComponent(groupId)}/admins`, {
      addAdmins: newAdmins,
      removeAdmins: removeAdmins,
    });
  };

  const inviteAll = (groupId: string) => {
    return postApi<void>(
      `/api/groups/${encodeURIComponent(groupId)}/inviteAll`,
    );
  };

  const getInviteLinks = (groupId: string) => {
    return fetchApi<InviteLink[]>(
      `/api/groups/${encodeURIComponent(groupId)}/invites`,
    );
  };

  const addInviteLink = (groupId: string, invite: InviteLink) => {
    return postApi<void>(
      `/api/groups/${encodeURIComponent(groupId)}/invites`,
      invite,
    );
  };

  const updateInviteLink = (groupId: string, invite: InviteLink) => {
    return putApi<void>(
      `/api/groups/${encodeURIComponent(groupId)}/invites/${encodeURIComponent(
        invite.id,
      )}`,
      invite,
    );
  };

  const deleteInviteLink = (groupId: string, invite: InviteLink) => {
    return deleteApi<void>(
      `/api/groups/${encodeURIComponent(groupId)}/invites/${encodeURIComponent(
        invite.id,
      )}`,
    );
  };

  const getInviteLinkClaimInfo = (groupId: string, inviteLinkId: string) => {
    return fetchApi<ClaimInfo>(
      `/api/groups/${encodeURIComponent(groupId)}/invites/${encodeURIComponent(
        inviteLinkId,
      )}/claiminfo`,
      true,
    );
  };
  const claimInviteLink = (groupId: string, inviteLinkId: string) => {
    return postApi<void>(
      `/api/groups/${encodeURIComponent(groupId)}/invites/${encodeURIComponent(
        inviteLinkId,
      )}/claim`,
    );
  };

  const getGroup = (groupId: string) => {
    return fetchApi<GroupInfo>(`/api/groups/${encodeURIComponent(groupId)}`);
  };

  const getCompositeChildren = (groupId: string) => {
    return fetchApi<GroupInfo[]>(
      `/api/groups/${encodeURIComponent(groupId)}/compositeChildren`,
    );
  };

  const addCompositeChild = (groupId: string, childId: string) => {
    return postApi<void>(
      `/api/groups/${encodeURIComponent(
        groupId,
      )}/compositeChildren/${encodeURIComponent(childId)}`,
    );
  };

  const removeCompositeChild = (groupId: string, childId: string) => {
    return deleteApi<void>(
      `/api/groups/${encodeURIComponent(
        groupId,
      )}/compositeChildren/${encodeURIComponent(childId)}`,
    );
  };

  return {
    fetchUserInfo,
    fetchMyGroups,
    fetchUser,
    fetchUserGroups,
    fetchManagableGroups,
    fetchGroupMembers,
    postGroupMembers,
    fetchUserCount,
    fetchUsers,
    getAccessToken,
    fetchManagableSpaces,
    addSpace,
    removeSpace,
    getSpaceMemberOverview,
    fetchCurrentSpacesOfGroup,
    getAdmins,
    changeAdmins,
    inviteAll,
    getUser,
    inviteUserToSpace,
    createNewSpace,
    getInviteLinks,
    updateInviteLink,
    addInviteLink,
    deleteInviteLink,
    getInviteLinkClaimInfo,
    claimInviteLink,
    getCompositeChildren,
    addCompositeChild,
    removeCompositeChild,
    getGroup,
  };
};
