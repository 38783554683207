export default defineNuxtRouteMiddleware((to) => {
  const { status, signIn, data } = useAuth();
  function requriresNoSignIn() {
    return to.path === '/' || to.name == 'invite-groupId-inviteId';
  }
  function requiresSignIn() {
    return !requriresNoSignIn();
  }

  function navToSign() {
    /**
     * We cannot directly call and/or return `signIn` here as `signIn` uses async composables under the hood, leading to "nuxt instance undefined errors", see https://github.com/nuxt/framework/issues/5740#issuecomment-1229197529
     *
     * So to avoid calling it, we return it immediately.
     */
    return signIn('keycloak', { callbackUrl: to.path }) as ReturnType<
      typeof navigateTo
    >;
  }

  const hasRefreshError =
    (data.value as any)?.error == 'RefreshAccessTokenError' ||
    (data.value as any)?.user?.error == 'RefreshAccessTokenError';

  if (hasRefreshError) {
    return navToSign();
  } else if (requiresSignIn() && status.value !== 'authenticated') {
    return navToSign();
  } else {
    // Return immediately if user is already authenticated
    return;
  }
});
