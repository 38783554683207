import { ResourceAccess, UserInfo } from '~/types/userInfo.model';

export const useKeycloakHelper = () => {
  interface HasNames {
    username?: string;
    firstName?: string;
    lastName?: string;
  }
  const getUserName = ({ firstName, lastName, username }: HasNames) => {
    if (firstName && lastName) return `${firstName} ${lastName}`;
    return username ?? '-';
  };
  const userName = (nameHolder: HasNames) =>
    computed(() => getUserName(nameHolder));

  const hasRealmRole = (
    resourceAccess: ResourceAccess | undefined,
    role: string,
  ) => {
    const result =
      resourceAccess?.['realm-management']?.roles?.includes(role) ?? false;
    return result;
  };

  const hasAccountRole = (
    resourceAccess: ResourceAccess | undefined,
    role: string,
  ) => {
    const result = resourceAccess?.account.roles?.includes(role) ?? false;
    return result;
  };
  interface HasResourceAccess {
    resource_access?: ResourceAccess | undefined;
  }

  const hasManageUserRole = function (user: HasResourceAccess) {
    return hasRealmRole(user?.resource_access, 'manage-users');
  };

  const hasViewUsersRole = function (user: HasResourceAccess) {
    return hasRealmRole(user?.resource_access, 'view-users');
  };

  const hasViewGroupsRole = function (user: HasResourceAccess) {
    return hasRealmRole(user?.resource_access, 'query-groups');
  };

  const hasManageGroupsRole = hasManageUserRole;

  const getAccessToken = () => {
    try {
      const { data } = useAuth();
      return (data.value?.user as any)?.access_token as string | undefined;
    } catch (error) {}

    return undefined;
  };

  return {
    getUserName,
    getAccessToken,
    userName,
    hasRealmRole,
    hasAccountRole,
    hasManageUserRole,
    hasViewUsersRole,
    hasViewGroupsRole,
    hasManageGroupsRole,
  };
};
