import { default as _91groupId_93YN72kjGqSNMeta } from "/builds/rotesteam/keycloak-group-manager/pages/gruppe/[groupId].vue?macro=true";
import { default as gruppen6P73lKk73SMeta } from "/builds/rotesteam/keycloak-group-manager/pages/gruppen.vue?macro=true";
import { default as indexWYfGnc5EpQMeta } from "/builds/rotesteam/keycloak-group-manager/pages/index.vue?macro=true";
import { default as _91inviteId_93R9PiNMdgbOMeta } from "/builds/rotesteam/keycloak-group-manager/pages/invite/[groupId]/[inviteId].vue?macro=true";
import { default as claimSuccess1yZ72ay0UqMeta } from "/builds/rotesteam/keycloak-group-manager/pages/invite/claimSuccess.vue?macro=true";
import { default as _91_91profileId_93_934z9ZfVD9RwMeta } from "/builds/rotesteam/keycloak-group-manager/pages/profil/[[profileId]].vue?macro=true";
import { default as profilxxxMbFsWXMRnoMeta } from "/builds/rotesteam/keycloak-group-manager/pages/profilxxx.vue?macro=true";
export default [
  {
    name: _91groupId_93YN72kjGqSNMeta?.name ?? "gruppe-groupId",
    path: _91groupId_93YN72kjGqSNMeta?.path ?? "/gruppe/:groupId()",
    meta: _91groupId_93YN72kjGqSNMeta || {},
    alias: _91groupId_93YN72kjGqSNMeta?.alias || [],
    redirect: _91groupId_93YN72kjGqSNMeta?.redirect || undefined,
    component: () => import("/builds/rotesteam/keycloak-group-manager/pages/gruppe/[groupId].vue").then(m => m.default || m)
  },
  {
    name: gruppen6P73lKk73SMeta?.name ?? "gruppen",
    path: gruppen6P73lKk73SMeta?.path ?? "/gruppen",
    meta: gruppen6P73lKk73SMeta || {},
    alias: gruppen6P73lKk73SMeta?.alias || [],
    redirect: gruppen6P73lKk73SMeta?.redirect || undefined,
    component: () => import("/builds/rotesteam/keycloak-group-manager/pages/gruppen.vue").then(m => m.default || m)
  },
  {
    name: indexWYfGnc5EpQMeta?.name ?? "index",
    path: indexWYfGnc5EpQMeta?.path ?? "/",
    meta: indexWYfGnc5EpQMeta || {},
    alias: indexWYfGnc5EpQMeta?.alias || [],
    redirect: indexWYfGnc5EpQMeta?.redirect || undefined,
    component: () => import("/builds/rotesteam/keycloak-group-manager/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91inviteId_93R9PiNMdgbOMeta?.name ?? "invite-groupId-inviteId",
    path: _91inviteId_93R9PiNMdgbOMeta?.path ?? "/invite/:groupId()/:inviteId()",
    meta: _91inviteId_93R9PiNMdgbOMeta || {},
    alias: _91inviteId_93R9PiNMdgbOMeta?.alias || [],
    redirect: _91inviteId_93R9PiNMdgbOMeta?.redirect || undefined,
    component: () => import("/builds/rotesteam/keycloak-group-manager/pages/invite/[groupId]/[inviteId].vue").then(m => m.default || m)
  },
  {
    name: claimSuccess1yZ72ay0UqMeta?.name ?? "invite-claimSuccess",
    path: claimSuccess1yZ72ay0UqMeta?.path ?? "/invite/claimSuccess",
    meta: claimSuccess1yZ72ay0UqMeta || {},
    alias: claimSuccess1yZ72ay0UqMeta?.alias || [],
    redirect: claimSuccess1yZ72ay0UqMeta?.redirect || undefined,
    component: () => import("/builds/rotesteam/keycloak-group-manager/pages/invite/claimSuccess.vue").then(m => m.default || m)
  },
  {
    name: _91_91profileId_93_934z9ZfVD9RwMeta?.name ?? "profil-profileId",
    path: _91_91profileId_93_934z9ZfVD9RwMeta?.path ?? "/profil/:profileId?",
    meta: _91_91profileId_93_934z9ZfVD9RwMeta || {},
    alias: _91_91profileId_93_934z9ZfVD9RwMeta?.alias || [],
    redirect: _91_91profileId_93_934z9ZfVD9RwMeta?.redirect || undefined,
    component: () => import("/builds/rotesteam/keycloak-group-manager/pages/profil/[[profileId]].vue").then(m => m.default || m)
  },
  {
    name: profilxxxMbFsWXMRnoMeta?.name ?? "profilxxx",
    path: profilxxxMbFsWXMRnoMeta?.path ?? "/profilxxx",
    meta: profilxxxMbFsWXMRnoMeta || {},
    alias: profilxxxMbFsWXMRnoMeta?.alias || [],
    redirect: profilxxxMbFsWXMRnoMeta?.redirect || undefined,
    component: () => import("/builds/rotesteam/keycloak-group-manager/pages/profilxxx.vue").then(m => m.default || m)
  }
]