<template>
  <NuxtLayout>
    <Toast />
    <ConfirmDialog></ConfirmDialog>
    <div class="max-w-5xl p-5 m-auto">
      <NuxtPage />
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
useHead({
  titleTemplate: (title) => {
    const page = title ? `${title} - ` : '';
    return `${page}Gruppenverwaltung`;
  },
});
</script>
